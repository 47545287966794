import { ReactComponent as ThreatList } from "../Assests/SVG/threatList.svg";
// import { ReactComponent as RiskManagementList } from "../Assests/SVG/RiskManagementList.svg";
import { ReactComponent as TaskList } from "../Assests/SVG/task-square.svg";
import { ReactComponent as Dashboard } from "../Assests/SVG/Dashboard.svg";
import { ReactComponent as IntelFlow } from "../Assests/SVG/IntelFlow.svg";
import { ReactComponent as Indicators } from "../Assests/SVG/Indicators.svg";
// import { ReactComponent as Support } from "../Assests/SVG/message-question.svg";
import { ReactComponent as Setting } from "../Assests/SVG/setting.svg";
// import { ReactComponent as MyWorkspace } from "../Assests/SVG/my-workspace.svg";
import { ReactComponent as ThreatProfile } from "../Assests/SVG/threat-profileSVG.svg";
// import { ReactComponent as InvestigationIcon } from "../Assests/SVG/InvestigationIcon.svg";

export const sideBarList = [
  {
    id: "threat-informed",
    header: "Threat Informed",
    headerIcon: <ThreatList />,
    href: "",
    children: [
      {
        id: "dashboard",
        header: "Dashboard",
        headerIcon: <Dashboard />,
        href: "/dashboard",
      },
      {
        id: "intel-flow",
        header: "Intel Flow",
        headerIcon: <IntelFlow />,
        href: "/intel-flow",
      },
      {
        id: "indicators",
        header: "Indicators",
        headerIcon: <Indicators />,
        href: "/indicators",
      },
    ],
  },
  // {
  //     header: "Risk Management",
  //     headerIcon: <RiskManagementList />,
  //     href: "risk-management",
  //     children: [],
  // },
  // {
  //     header: "Investigation",
  //     headerIcon: <InvestigationIcon />,
  //     href: "investication",
  //     children: [],
  // },
  {
    id: "my-task",
    header: "My Tasks",
    headerIcon: <TaskList />,
    href: "/my-tasks",
    children: [],
  },
  // {
  //   id: "my-workspace",
  //   header: "My Workspace",
  //   headerIcon: <MyWorkspace />,
  //   href: "/my-workspace",
  //   children: [],
  // },
  {
    id: "threat-profile",
    header: "Threat Profile",
    headerIcon: <ThreatProfile />,
    href: "/threat-profile",
    children: [],
  },
  {
    id: "settings",
    header: "Settings",
    headerIcon: <Setting />,
    href: "/settings",
    children: [],
  },
  // {
  //   id: "support",
  //   header: "Support",
  //   headerIcon: <Support />,
  //   href: "/support",
  //   children: [],
  // },
];
